<template>
  <div
    ref="container"
    :class="{ container: true, 'is-active': active }"
    @pointerdown.capture.prevent.stop="down"
    @pointermove.capture.prevent.stop="move"
    @pointerleave.stop="leave"
    @pointerup.stop="up"
  >
    <div
      ref="thumb"
      class="thumb"
      :style="thumbStyle"
    >
      <div class="name">
        <Icon v-if="icon" :name="icon" tiny />
        <div v-if="hasSlot" class="controls">
          <slot />
        </div>
        {{ nameString }}
      </div>
      <div class="value">
        {{ valueString }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: 'LineSlider',
  components: {
    Icon,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    identifier: {
      type: String,
      default: 'slider',
    },
    name: {
      type: String,
      default: 'Slider',
    },
    icon: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    unit: {
      type: String,
      default: '%',
    },
    labels: {
      optional: true,
      type: Array,
      default: () => [],
    },
    type: {
      optional: true,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pointerDown: false,
      // value: this.initialValue,
    }
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default && !!this.$slots.default[0]
    },
    thumbStyle() {
      if (!this.active || this.type === "action") {
        return { bottom: 0 };
      }
      const container = this.$refs.container;
      const amplitude = this.max - this.min;

      if (container) {
        const maxBottom = parseFloat(window.getComputedStyle(this.$refs.container, ":before").height);

        const pixels = ((this.value - this.min) / amplitude) * maxBottom;
        return { bottom: `${pixels}px` };
      }
      const percent = Math.max(0, ((this.value - this.min) / amplitude) * 100);
      return { bottom: `${percent}%` };
    },
    numericValueString() {
      let numeric;
      if (this.min === 0 && this.max === 100) {
        numeric = Math.trunc(this.value);
      } else {
        numeric = this.value.toFixed(1);
      }
      return `${numeric}${this.unit}`;
    },
    valueString() {
      if (this.type === "action") {
        return "➔";
      }
      if (this.type === "bool") {
        return (this.value === 0) ? "Off" : "On";
      }
      if (this.labels && this.labels[Math.floor(this.value)]) {
        return this.labels[Math.floor(this.value)].value;
      }
      return this.numericValueString;
    },
    nameString() {
      if (this.labels && this.labels[Math.floor(this.value)]) {
        return this.labels[Math.floor(this.value)].name;
      }
      return this.name;
    },
  },
  methods: {
    down() {
      this.pointerDown = true;
      window.clearTimeout(this.leaveTimer);
      this.leaveTimer = null;
    },
    move(e) {
      if (!this.pointerDown) {
        return;
      }
      if (!this.active) {
        this.$emit('activate');
      }
      if (e.target.tagName === 'BUTTON') {
        return;
      }
      this.$emit('sliding');
      window.clearTimeout(this.leaveTimer);
      this.leaveTimer = null;

      const target = e.target.closest('.container');
      const height = target.offsetHeight - 30;
      const amplitude = this.max - this.min;
      const percent = 100 - (((e.offsetY) / height) * 100);
      this.setValue(percent);
    },
    up(e) {
      this.$emit('sliding');
      if (this.active) {
        this.move(e);
      } else {
        this.$emit('activate');
      }
      this.pointerDown = false;
    },
    leave() {
      const self = this;
      if (this.value > this.max * 0.9) {
        this.setValue(100);
      }
      this.leaveTimer = window.setTimeout(() => { self.pointerDown = false }, 600);
    },
    setValue(percent) {
      const amplitude = this.max - this.min;
      let val = this.min + (percent / 100) * amplitude;
      val = Math.max(this.min, Math.min(this.max, val));

      if (this.labels.length) {
        val = Math.floor(val);
      } else if (amplitude < 20) {
        val = Math.round(val * 2) / 2.0;
      } else {
        val = Math.round(val);
      }
      // this.value = val;
      this.$emit('changed', this.identifier, val);
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: 1.25rem 0 0;
  font-size: 1.25rem;
  margin: .5rem 0;
  width: 100%;
  height: 100%;

  &:before {
    // used as a witness to get the results of calc(100% - 1.75rem);
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background: red;
    height: calc(100% - 1.75rem);
  }
}
.thumb {
  display: grid;
  grid-template-areas: "icon ."
                       "separator separator"
                       "name value";

  grid-template-rows: 1.5rem auto 1.5rem;
  row-gap: .25rem;
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: .25rem;
  pointer-events: none;
  color: var(--color-primary);

  justify-content: space-between;
  &::before {
    content: "";
    grid-area: separator;
    border-top: var(--value-height) solid var(--color-primary);
  }

}
.controls {
  display: none;
  flex: 0;
}
.Icon {
  // grid-area: icon;
  padding: 0 0 .25rem 0;
  margin-right: .25rem;
}
.name { grid-area: name; }
.value { grid-area: value; }

.name {
  display: flex;
  align-items: center;
}
.thumb-inner {
  position: relative;
  width: 100%;
}
.is-active .controls {
  display: inline-block;
}
</style>
