<template>
  <div>
    <span @click="clickedSwitcher">
      <Icon tiny name="menu-bars" />
    </span>
    <span @click="clickedLauncher">
      <Icon tiny name="app-dots" />
    </span>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: "AppControls",
  components: {
    Icon,
  },
  methods: {
    clickedSwitcher() { this.$emit("toggle-switcher") },
    clickedLauncher() { this.$emit("toggle-launcher") },
  },
}
</script>

<style scoped>
.AppControls {
  order: 3;
  display: flex;
  align-self: flex-end;
}
.AppControls * + * {
  margin-left: 1rem;
}
</style>
