<template>
  <div :class="deviceClass()">
    <div class="Alignment-Group">
      <div class="Device-Group">
        <slot />
        <div class="Device-Decoration" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Device",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    deviceClass() {
      return `Device-Context Device__${this.type}`;
    },
  },
};
</script>

<style lang="scss">
html {
  --app-width: 75rem;
}

.Device-Context {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url("~@/assets/context-bg.png") center center no-repeat;
  background-size: cover;
}

.Device__grand-lutrin {
  background: none;
  justify-content: flex-end;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("~@/assets/grand-lutrin.png") center bottom no-repeat;
    z-index: 10;
    pointer-events: none;
    // opacity: .5;
  }
  .Shadow { display: none; }
  .Alignment-Group {
    align-self: center;
    position: relative;
    left: -60px;
    bottom: 188px;
    overflow: hidden;
    // border-radius: .75rem;
  }
  .Device-Group {
    width: 1180px;
    height: 623px;
  }
  @media (max-height: 850px) {
    &::after {
      background-position-y: -450px;
    }
    justify-content: flex-start;
    .Alignment-Group {
      bottom: auto;
      top: 20px;
    }
  }
}

.Device__petit-lutrin {
  background: none;
  justify-content: flex-end;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("~@/assets/petit-lutrin.png") center bottom no-repeat;
    z-index: 10;
    pointer-events: none;
    // opacity: .5;
  }
  .Shadow { display: none; }
  .Alignment-Group {
    align-self: center;
    position: relative;
    left: -89px;
    bottom: 230px;
    overflow: hidden;
    // border-radius: 1rem;
  }
  .Device-Group {
    width: 1290px;
    height: 472px;
  }
  @media (max-height: 720px) {
    &::after {
      background-position-y: -682px;
    }
    justify-content: flex-start;
    .Alignment-Group {
      bottom: auto;
      top: 20px;
    }
  }
}

.Device__none {
  background-image: none;
  .Alignment-Group {
    height: 100%;
    width: 100%;
  }
  .Device-Group {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .Interface {
    position: relative;
    height: calc(100% - 3rem);
    width: 100%;
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .Device-Decoration {
    height: 3rem;
    background: black;
    width: 100%;
  }
}

@media screen and (max-width: 1334px) {
  .Device__grand-lutrin {
    align-items: flex-start;
    .Alignment-Group {
      align-self: flex-start;
      left: 14px;
    }
  }
  .Device__grand-lutrin::after {
    background-position-x: -296px;
  }
}
@media screen and (max-width: 1500px) {
  .Device__petit-lutrin {
    align-items: flex-start;
    .Alignment-Group {
      align-self: flex-start;
      left: 14px;
    }
  }
  .Device__petit-lutrin::after {
    background-position-x: -212px;
  }
}
</style>
