<template>
  <div @click.stop="showIndicators">
    <Clock class="Clock" @clicked="toggleEq" />

    <Equalizer v-if="eqVisible" class="Equalizer" />

    <transition name="fade">
      <div v-if="!expanded && indicatorsVisible" class="Indicators" v-touch:swipe.stop="noop">
        <LineSlider v-for="item in fav"
                    :key="item.title"
                    :value="sliders[item.title]"
                    :name="item.title"
                    :icon="item.icon"
                    :identifier="item.title"
                    :min="minFor(item.type)"
                    :max="maxFor(item.type)"
                    :unit="unitFor(item.type)"
                    :type="item.type"
                    @changed="sliderChanged"
                    @sliding="showIndicators"
        />
      </div>
    </transition>
    <transition name="fade">
      <GeneralMenu v-if="expanded" :menu="menu" class="GeneralMenu" />
    </transition>
  </div>
</template>

<script>
import Clock from './Clock.vue';
import Equalizer from './Equalizer.vue';
import MenuItem from './MenuItem.vue';
import RangeSlider from './RangeSlider.vue';
import LineSlider from './LineSlider.vue';
import GeneralMenu from './GeneralMenu.vue';
import { menu, favorite } from '@/menu-icons.yaml';

const menuData = Object.freeze(menu);
const favData = Object.freeze(favorite);

export default {
  name: "Controller",
  components: {
    Clock,
    Equalizer,
    LineSlider,
    GeneralMenu,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    indicatorsVisible: Boolean,
  },
  data() {
    return {
      notificationsVisible: false,
      activeMenu: undefined,
      sliders: new Object(),
      menu: menuData,
      fav: favData,
      eqVisible: false,
    };
  },
  mounted() {
    this.setupFavorites();
  },
  methods: {
    menuTitles() {
      // return this.menu.map(item => Object.keys(item)[0]);
      return Object.keys(this.menu);
    },
    noop() {},
    label(item) {
      let string = item;
      if (typeof string !== "string") {
        string = Object.keys(item)[0];
      }
      let match = string.match(/(.*) \(.*\)/);
      return (match) ? match[1] : string;
    },
    icon(item) {
      let string = item;
      if (typeof string !== "string") {
        string = Object.keys(item)[0];
      }
      let match = string.match(/.*\((.*)\)/);
      return (match) ? match[1] : null;
    },

    menuClicked(menu) {
      this.activeMenu = menu;
    },
    sliderChanged(slider, value) {
      // painful walkaround some weirdobserver logic.
      let sliderState = {};
      Object.keys(this.sliders).forEach(k => {
        sliderState[k] = this.sliders[k];
      });
      sliderState[slider] = value;
      this.sliders = sliderState;
    },
    toggleEq() {
      this.eqVisible = !this.eqVisible;
    },
    showIndicators() {
      this.$emit("show-indicators");
    },
    setupFavorites() {
      this.fav.forEach(f => this.sliders[f.title] = (f.value) ? f.value : 0);
      this.sliderChanged();
    },
    minFor(type) {
      switch (type) {
        case "temp":
          return 14;
        case "bool":
          return 0;
        case "action":
          return 0;
        case "slider":
          return 0;
      }
    },
    maxFor(type) {
      switch (type) {
        case "temp":
          return 24;
        case "bool":
          return 0.5;
        case "action":
          return 0;
        case "slider":
          return 100;
      }
    },
    unitFor(type) {
      switch (type) {
        case "temp":
          return "°C";
        case "bool":
          return null;
        case "action":
          return null;
        case "slider":
          return "%";
      }
    },
  },
}
</script>

<style scoped>
.Controller {
  display: grid;
  grid-template-areas: "clock menu"
              "items items";
  grid-template-rows: 3rem auto;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: 100%;
}

.Clock {
  grid-area: clock;
}
.Device__lutrin .Clock {
  margin-bottom: 1rem;
}

.Menu {
  display: flex;
  grid-area: menu;
}
.MenuItem {
  margin-left: 2rem;
}

.Equalizer {
  grid-area: menu;
}

.Indicators {
  align-self: start;
  grid-area: items;
  height: 7rem;
  display: grid;
  grid-gap: 1rem;
  grid-auto-columns: 15rem;
  grid-auto-flow: column;
  margin-top: 2rem;
  padding-bottom: .2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scroll-padding-left: .5rem;
  scrollbar-width: none;
}
.Indicators::-webkit-scrollbar {
  display: none;
}
.Indicators > * {
  scroll-snap-align: start;
}

.fade-enter-active {
  transition: opacity .5s ease;
}
.fade-leave-active {
  /* transition-duration: 0s; */
}
.fade-leave-to, .fade-enter {
  opacity: 0;
}

.GeneralMenu {
  grid-area: items;
}
</style>
