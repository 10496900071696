<template>
  <div>
    <img :src="source(appScreenName(id, style))" alt="A running application">
  </div>
</template>

<script>
export default {
  name: 'RunningApp',
  props: {
    id: {
      type: String,
      required: true,
    },
    // height: {
    //   type: Number,
    //   default: 500,
    // },
  },
  data() {
    return {
      style: null,
    };
  },
  // computed: {
  //   figureHeight() {
  //     if (this.height < 0) {
  //       return {};
  //     }
  //     return {
  //       // height: `${this.height}px`,
  //       // width: `${this.height * 9 / 16}px`,
  //     };
  //   },
  // },
  methods: {
    appScreenName(id, modifier = null) {
      const suffix = (modifier) ? `-${modifier}.png` : '.png';
      return `running-app-${id}${suffix}`;
    },
    source(name) {
      const dir = require.context('../assets/applications/', false, /\.png$/);
      return dir(`./${name}`);
    },
  },
};
</script>

<style scoped>
.RunningApp {
  transform-origin: center bottom;
}
.RunningApp.RunningApp__Mobile {
  grid-row: span 2;
  grid-column: span 1;
}
.RunningApp__Widget {
  grid-row: span 1;
  grid-column: span 1;
}
.RunningApp__Double {
  grid-row: span 2;
  grid-column: span 2;
}
.RunningApp__Wide {
  grid-row: span 2;
  grid-column: span 3;
}
.RunningApp.RunningApp__Full {
  position: absolute;
  top: -.5rem;
  left: -.5rem;
  right: -.5rem;
  bottom: -3rem;
  z-index: 10;
  width: auto;
  height: auto;
  /* grid-row: span 2; */
  /* grid-column: span 4; */
}
img {
  display: block;
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
  border-radius: calc(var(--app-width) / 33);
}
</style>
