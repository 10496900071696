import StaticGrid from "@/../api/v2/StaticGrid";
import Mirror from "@/../api/v2/Mirror";
import AudioInput from "@/../api/v2/AudioInput";
import AudioPlayer from "@/../api/v2/AudioPlayer";

window.p5 = p5;

(async () => {
  await import("../../lib/p5.sound");
})();

const sceneParams = {
  backgroundColor: "#BACDCF",
  clockColor: "#FFFFFF",
  colorPrimary: "#FFFFFF",
  colorSecondary: "#019966",

  panelBackground: "#601E00DD",
  appsBackground: "#4A1700DD",

  panelVibrant: "#9A1700",
  panelSeparator: "#fff",

  blackOnWhite: false,
  AudioInput: false,
  AudioPlayer: true,

  frameRate: 30,

  soundSources: {
    Rock: { file: "/assets/time.mp3", amp: 0.5 },
  },
};

const matrices = {
  StaticGrid: {
    class: StaticGrid,
    settings: {},
    params: {
      lineColor: "#ffffff22",

      offsetX: 0,
      offsetY: 0,
      scale: 1,
      cellW: 15,
      cellH: 180,
      linePadding: 1,
      lineWeight: 0.5,
    },
  },
  Mirror: {
    class: Mirror,
    settings: {},
    params: {
      offsetX: 0,
      offsetY: 0,
      lineColor: "#ffffff",

      lineWeight: 0.25,
      maxLineWeight: 0.25,
      weightVariation: true,

      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 1,
      sensibility: 20,
      scale: 1,
      cellW: 30,
      cellH: 360,
      linePadding: 2,
    },
  },
  AudioInput: {
    class: AudioInput,
    settings: {},
    params: {
      lineColor: "#000",

      // lineColorComponents: [64, 43, 61, 1],
      lineColorComponents: [0, 0, 0, 1],
      altLineColorComponents: [0, 0, 100, 1],
      useAltTheme: false,

      w: 1920,
      h: 10,
      asymetry: 10,
      audioInAmp: 0.05,
      volumeThreshold: 1,
      fftSmoothing: 0.4,
      minFreq: 0,
      maxFreq: 1200,
      octaveThreshold: 0.5,
      scale: 1,
      cellW: 30,
      cellH: 200,
      linePadding: 2,
      lineWeight: 0.125,
      maxLineWeight: 0.125,
      weightVariation: true,
      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 0,
      sensibility: 20,
    },
  },
  AudioPlayer: {
    class: AudioPlayer,
    global: "eq",
    settings: {},
    params: {
      source: "Rock",
      lineColor: "#FF5100",
      lineColorComponents: [0, 100, 50, 1],
      useAltTheme: false,

      w: 1920,
      h: 180,
      offsetY: 0,
      asymetry: 10,
      volumeThreshold: 33,
      fftSmoothing: 0.5,
      minFreq: 0,
      maxFreq: 16000,
      octaveThreshold: 0.5,
      scale: 1,
      cellW: 30,
      cellH: 200,
      linePadding: 2,
      lineWeight: 0.125,
      maxLineWeight: 0.125,
      weightVariation: true,
      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 0,
      sensibility: 5,
    },
  },
};

const theme = {
  sceneParams,
  matrices,
};
export default theme;
