import Matrix from "./Matrix";
import Cell from "./Cell";
import * as Method from "./TintMethods";

function map(...args) {
  return state.instance.map(...args);
}

export default class Mirror extends Matrix {
  constructor(name, params, active = true) {
    super(name, params, active);
    this.scopedParams = ["cellW", "cellH", "scale", "sensibility"];
  }
  initialize() {
    const p = state.instance;
    if (!state.capture) {
      state.capture = p.createCapture(p.VIDEO);

      // const videoSize = state.capture.size();
      // state.capture.size(
      //   Math.floor(videoSize.width / 5),
      //   Math.floor(videoSize.height / 5)
      // );
      state.capture.size(128, 96);
      state.capture.hide();
    }
    this.configure();
  }
  deinit() {
    state.capture.remove();
  }
  configure() {
    const p = state.instance;

    this.cells = [];
    let w = this.params.cellW * this.params.scale;
    let h = this.params.cellH * this.params.scale;
    let cellOuterWidth = w - (this.params.overlapHoriz || 0);
    let cellOuterHeight = h - (this.params.overlapVert || 0);

    let firstLineOffset = cellOuterHeight;

    let numCol = Math.ceil(p.width / cellOuterWidth + 1);
    let numRow = Math.ceil(p.height / cellOuterHeight + 1);

    for (let row = 0; row < numRow; row++) {
      for (let col = 0; col < numCol; col++) {
        let x = col * cellOuterWidth + this.params.offsetX;
        let y = row * cellOuterHeight - firstLineOffset + this.params.offsetY;
        let cell = new Cell(x, y, row, col);
        cell.tintMethod = Method.MIRROR;
        this.cells.push(cell);
      }
    }
  }
  colorAtPoint(_x, _y, offsetX = 0, offsetY = 0) {
    let x = _x - offsetX;
    let y = _y - offsetY;

    x = map(x, 0, state.w, 0, state.capture.width);
    y = map(y, 0, state.h, 0, state.capture.height);

    if (x < 0 || y < 0 || x > state.capture.width || y > state.capture.height) {
      return;
    }

    let pix = state.capture.pixels;
    let captureX = Math.floor(x);
    let captureY = Math.floor(y);
    let offset;

    // Mirror image if necessary
    offset =
      (captureY * state.capture.width + state.capture.width - captureX - 1) * 4;

    if (offset < 0) {
      return;
    }

    let color = [pix[offset], pix[offset + 1], pix[offset + 2], 255];
    // if (isNaN(color[0])) debugger;

    return color;
  }

  tick() {
    const p = state.instance;
    if (state.capture && state.capture.loadedmetadata) {
      state.capture.loadPixels();
      this.cells.forEach(c => {
        const colorArray = this.colorAtPoint(
          c.x,
          c.y,
          this.params.offsetX,
          this.params.offsetY
        );
        if (!colorArray) {
          c.input();
          return;
        }
        const [r, g, b, a] = colorArray;
        c.input(p.color(`rgba(${r}, ${g}, ${b}, ${a / 255})`));
      });
    }
  }
}
