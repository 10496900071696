import startupLutrin from "@/configurations/startup-lutrin";
import lutrinBlue from "@/configurations/lutrin-i";

const Themes = Object.freeze({
  startup: Object.freeze(startupLutrin),
  main: Object.freeze(lutrinBlue),
});

function setTheme(theme) {
  this.$emit("set-theme", theme);
}

export { setTheme };
export default Themes;
