import Queue from './Queue';

export default class ColorQueue extends Queue {
  average() {
    push();
    colorMode(HSB);
    let h = 0, s = 0, b = 0, a = 0;
    for (let col of this.storage) {
      h += hue(col) / this.storage.length;
      s += saturation(col) / this.storage.length;
      b += brightness(col) / this.storage.length;
      a += col.hsba[3] / this.storage.length;
    }
    let avg = color(h, s, b, a);
    pop();
    return avg;
  }

  values() {
    return this.storage.map(c => brightness(c));
  }
}
