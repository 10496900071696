<template>
  <div :class="menuClass" @click.stop="clicked">
    <div class="icon-container">
      <Icon v-if="active" :name="icon" small
            class="glow"
      />
      <Icon :name="icon" small />
    </div>
    <transition name="fade">
      <span v-if="active" class="label">{{ label }}</span>
    </transition>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import { parameterize } from "inflected";

export default {
  name: "MenuItem",
  components: {
    Icon,
  },
  props: {
    current: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    active() {
      return parameterize(this.label) === this.current;
    },
    menuClass() {
      return {
        MenuItem: true,
        MenuItem__active: this.active,
        [`MenuItem-${this.icon}`]: true,
      }
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked', parameterize(this.label));
    },
  },
}
</script>

<style scoped>
.MenuItem {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}
.MenuItem__active {}
.MenuItem-weather figure {
  padding: 0;
}
.icon-container {
  display: grid;
}
.icon-container > * {
  grid-row: 1;
  grid-column: 1;
}
.Icon {
  margin-right: .5rem;
}
.glow {
  filter: blur(2px);
}

.fade-enter-active {
  transition: all .5s ease;
}
.fade-leave-active {
  transition-duration: 0s;
}
.fade-leave-to, .fade-enter {
  opacity: 0;
  max-width: 0;
}
</style>
