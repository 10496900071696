<template>
  <ul>
    <li v-for="i in volume" :key="i"></li>
  </ul>
</template>

<script>
import MicVolumeMeter from "@adrianhurt/mic-volume-meter";

export default {
  name: "Equalizer",
  data() {
    return {
      volume: 0,
    };
  },
  mounted() {
    this.volumeMeter = new MicVolumeMeter();

    this.volumeMeter.switchOn({
      clipLevel: 0.98,
      averaging: 0.95,
      clipLag: 750,
    }).then(() => {
      this.volumeMeter.start({ onNewMeasure: this.volumeMeasure, interval: 30 });
    }).catch(() => {
      console.error("Volume measure error");
    });
  },
  destroyed() {
    this.volumeMeter.switchOff();
    this.volumeMeter = null;
  },
  methods: {
    volumeMeasure({ volume }) {
      if (volume <= 0.5) {
        this.setVolume(volume * 750);
      }
      if (volume > 0.5) {
        this.setVolume((volume - 0.75) * 250);
      }
    },
    setVolume(val) {
      this.volume = Math.floor(Math.min(Math.max(0, val), 200));
    },
  },
}
</script>

<style scoped>
.Equalizer {
  display: flex;
  margin-left: 1rem;
}
li {
  height: 2rem;
  width: 1px;
  background: var(--color-primary);
  margin-left: 2px;
}
.fade-enter-active {
transition: opacity .5s ease;
}
.fade-leave-active {
/* transition-duration: 0s; */
}
.fade-leave-to, .fade-enter {
opacity: 0;
}
</style>
