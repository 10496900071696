<template>
  <div :style="rowSizes">
    <div v-touch:swipe="swipe" ref="top" class="top">
      <slot name="top" />
    </div>
    <div v-if="hasBottomSlot()" :class="tackClass()" @pointerdown="moveStart"></div>
    <div ref="bottom" class="bottom">
      <slot name="bottom" :height="bottomHeight()" :device="device" />
    </div>
  </div>
</template>

<script>
import { bindEvents, moveStart, moving, moveEnd } from "./PanelDragging";
import gsap from "gsap";

export default {
  name: "Splitter",
  props: {
    device: {
      type: String,
      required: true,
    },
    expanded: Boolean,
  },
  data() {
    return {
      // constants
      draggable: true,
      minHeaderHeight: 60,
      minBottomHeight: 30,
      bottomMargin: 30,

      // variables
      initialDragOffsetTop: 190,
      dragging: false,
      dragDistance: 0,
      headerHeight: 60,
      splitterHeight: 650,
    }
  },
  computed: {
    rowSizes() {
      let bottomHeight = this.splitterHeight - this.headerHeight - this.bottomMargin;
      if (bottomHeight < 0) { bottomHeight = 0; }
      return {
        "grid-template-rows": `${this.headerHeight}px 1fr ${bottomHeight}px`,
      };
    },
  },
  watch: {
    expanded(newValue) {
      if (newValue) {
        this.expand();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.bindEvents();
      this.setup();
    });
  },
  methods: {
    bindEvents, moveStart, moving, moveEnd,
    tackClass() {
      return [
        "tack",
        { "tack__active": this.dragging },
      ];
    },
    hasBottomSlot() {
      const bottomSlot = !!this.$scopedSlots.bottom;
      return bottomSlot;
    },
    currentHeights() {
      const topElement = this.$refs.top;
      const bottomElement = this.$refs.bottom;

      if (!bottomElement) { return { top: 0, bottom: 0 }; }

      const top = topElement.offsetHeight;
      const bottom = bottomElement.offsetHeight;

      return { top, bottom };
    },
    bottomHeight() {
      return this.currentHeights().bottom;
    },
    setup() {
      const newHeight = this.$el.offsetHeight;

      if (!this.hasBottomSlot()) {
        this.$nextTick(() => {
          this.headerHeight = newHeight;
        });
      } else {
        window.requestAnimationFrame(() => {
          this.headerHeight = this.headerHeight + 0.0000001;
        });
      }

      if (newHeight !== this.splitterHeight) {
        this.splitterHeight = newHeight;
        this.recalculate();
      }
    },
    swipe(direction, event) {
      if (event.target.parentElement.classList.contains("Indicators")) { console.log('caught'); return; }
      if (this.device === "petit-lutrin") { return; }
      if (direction === "bottom") {
        this.$emit("expand-menu");
      } else {
        this.$emit("unexpand-menu");
      }
    },
    expand() {
      gsap.to(this, {
        headerHeight: this.splitterHeight - 44,
      });
    },
    snap() {
      const tackPosition = this.initialDragOffsetTop - this.dragDistance;
      const snapPoints = [
        this.minHeaderHeight,
        this.minHeaderHeight + 130,
        this.splitterHeight / 2 + 5,
        this.splitterHeight - 140,
        this.splitterHeight - 44,
      ];
      const snapTo = snapPoints.sort((a, b) => { return Math.abs(a - tackPosition) - Math.abs(b - tackPosition) })[0];
      gsap.to(this, {
        headerHeight: snapTo,
      });
    },
    recalculate() {
      this.$emit("unexpand-menu");
      let newHeight = this.initialDragOffsetTop - this.dragDistance;
      this.headerHeight = Math.min(Math.max(this.minHeaderHeight, newHeight), this.splitterHeight - this.minBottomHeight);
    },
  },
}
</script>

<style scoped>
.Splitter {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.top { overflow: hidden; }
.bottom {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.tack {
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(1.75rem);
}
.tack:hover::before {
  /* opacity: .75; */
}
.tack__active::before {
  box-shadow: 0 0 1rem rgba(255, 255, 255, 1);
}
.tack::before {
  content: "";
  position: absolute;
  left: 1rem;
  right: 1rem;
  height: var(--value-height);
  background: rgb(255, 255, 255);
  border-radius: .25rem;
}
</style>
