<template>
  <div @click="clicked">
    <figure v-if="appearance === 'picture'">
      <img src="@/assets/mirror.png">
      <!-- <img src="@/assets/matrix.png"> -->
    </figure>
    <div v-if="appearance === 'color'" class="Mirror-ColorBackground"></div>
    <section v-else id="canvas-container" class="CanvasContainer">
      <slot></slot>
    </section>
  </div>
</template>

<script>
import SceneState from "@/../api/v2/SceneState";
import P5Canvas from "@/concerns/P5Canvas";

export default {
  name: "Mirror",
  props: {
    appearance: {
      default: "picture",
      validator: val => ["canvas", "picture", "color"].indexOf(val) !== -1,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  watch: {
    theme(newValue) {
      if (this.appearance !== "canvas") {
        return;
      }
      const state = new SceneState(newValue);
      this.canvas.updateState(state);
    },
  },
  mounted() {
    if (this.appearance !== "canvas") {
      return;
    }
    const state = new SceneState(this.theme);
    this.canvas = new P5Canvas(state);
  },
  methods: {
    reload() {
      if (this.appearance !== "canvas") {
        return;
      }
      const state = new SceneState(this.theme);
      this.canvas.updateState(state);
    },
    clicked() {
      this.$emit("clicked");
      // this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.Mirror,
.Mirror-ColorBackground,
figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Mirror-ColorBackground {
  background-color: #bacdcf;
}
figure {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
