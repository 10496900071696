<template>
  <div @click="clicked">
    <div class="ClockDisplay">
      <div ref="now" class="Now">
        {{ time }}
      </div>
    </div>
    <div class="NotificationPill" />
  </div>
</template>

<script>
import gsap from "gsap";
import Modes from "@/Modes";

// import * as Digits from "@/Digits";
// import StatusItems from "@/assets/statusitems.svg";

export default {
  name: "Clock",
  data() {
    return {
      Modes,
      // Digits,
      now: new Date(),
    };
  },
  computed: {
    time() {
      return this.now.toLocaleTimeString("fr", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    window.setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped lang="scss">
.Clock {
  position: relative;
  color: var(--color-primary);
  display: flex;
  align-self: start;
}
.Now {
  font-size: 2.5em;
  font-weight: 200;
}

.NotificationPill {
  position: relative;
  height: .5rem;
  width: .5rem;
  top: .5rem;
  background: var(--color-secondary);
  border-radius: 1rem;
  margin-left: .25rem;
}
</style>
