import Queue from './Queue';
import ColorQueue from './ColorQueue';
import * as Method from './TintMethods';
import * as math from 'mathjs';

function map(...args) {
  return state.instance.map(...args);
}

/* eslint-disable no-param-reassign */
export default class Cell {
  constructor(x, y, row, col) {;
    const p = state.instance;
    this.useColorQueue = false;

    this.clear();
    this.x = x;
    this.y = y;
    this.column = col,
    this.row = row;

    this.data = {};

    this.inputColor = p.color(0, 0, 0, 0);
    this.opacity = 1;
    this.colorHistory = this.useColorQueue ? new ColorQueue(state.current.sensibility) : new Queue(state.current.sensibility);
    // this.activationHistory = new Queue(3);

    // this.debug = true;
  }

  clear() {
    this.previousState = Object.assign({}, this.render);
    this.render = {
      color: this.inputColor,
      weight: state.current.lineWeight * state.current.scale,
      xOffset: 0,
      yOffset: 0,
      active: false
    };
  }

  activate()   {
    this.render.active = true;
    // if (this.opacity < 0.8) {
      // this.opacity += 0.1;
    // }
    // this.activationHistory.enqueue(true);
  }
  deactivate() {
    // if (this.opacity > 0) {
      // this.opacity -= 0.001;
    // } else {
      this.render.active = false;
    // }
    // this.activationHistory.enqueue(false);
  }

  input(inputColor) {
    const p = state.instance;

    if (inputColor === undefined) {
      inputColor = this.render.color || p.color(0, 0, 0, 0);
    } else if (!inputColor.mode) {
      inputColor = p.color(inputColor);
    }
    this.inputColor = inputColor;
    if (this.useColorQueue) {
     this.colorHistory.enqueue(inputColor);
    } else {
     this.colorHistory.enqueue(p.brightness(inputColor));
    }

    this.clear();
    this.tint();
  }

  tint() {
    switch (this.tintMethod) {
      case Method.PALETTE:
        this.paletteTint();
        break;
      case Method.MIRROR:
        this.mirrorTint();
        break;
      case Method.EQ:
        this.eqTint();
        break;
      case Method.BGGRID:
        this.gridTint();
        break;
      case Method.DIRECT:
        this.directTint();
        break;
      case Method.RAWTINT:
        this.rawTint();
        break;
      case Method.NOISY:
        this.noisyTint();
        break;
      default:
        this.uniformTint();
    }
  }

  defaultTint() {
    let brightnessDeviation = this.brightnessDeviation();
    let averageBrightness = this.averageBrightness();

    if (brightnessDeviation > state.current.inactivationThreshold) {
      this.activate();
    } else {
      this.deactivate();
    }

    this.render.xOffset = averageBrightness;
    this.render.yOffset = map(brightnessDeviation, 0, 2, -(state.current.vertMovement || 0), (state.current.vertMovement || 0));

    this.render.weight = state.current.lineWeight * state.current.scale;
    if (state.current.weightVariation) {
      this.render.weight *= (brightnessDeviation - state.current.inactivationThreshold);
    }
  }

  paletteTint() {
    const p = state.instance;

    this.defaultTint();
    const col = p.color(state.palette[Math.abs(Math.floor(frameCount / state.frameRate) - this.column) % state.palette.length]);
    col.setAlpha(this.opacity);
    this.render.color = col;
  }

  mirrorTint() {
    const p = state.instance;

    this.defaultTint();
    const col = p.color(state.lineColor());
    col.setAlpha(this.opacity);
    if (p.frameCount % 30 === 0 && this.x < 10 && this.y < 10) {
      // console.log(col.toString());
    }
    this.render.color = col;
  }

  eqTint() {
    this.defaultTint();
    this.render.color = (state.current.useAltTheme) ? state.current.altLineColorObject : state.current.lineColorObject;
  }

  gridTint() {
    const p = state.instance;

    this.defaultTint();
    const lineColor = state.lineColor();
    let [h, s, a] = [p.hue(lineColor), p.saturation(lineColor), p.alpha(lineColor)];
    let average = this.averageColor();
    if (average.mode) {
      this.render.color = average;
    } else {
      p.push();
      p.colorMode(p.HSB);
      this.render.color = p.color(h, s, average, a);
      p.pop();
    }
  }

  directTint() {
    this.defaultTint();
    this.render.color = this.inputColor;
  }

  rawTint() {
    this.render.active = true;
    this.render.color = this.inputColor;
  }

  noisyTint() {
    const noiseFactor = noise(this.x, this.y, frameCount);

    this.render.active = true;
    this.render.xOffset = map(noiseFactor, 0, 1, 0, state.current.horizMovement);
    this.render.yOffset = map(noiseFactor, 0, 1, -(state.current.vertMovement || 0), (state.current.vertMovement || 0));

    this.render.weight = state.current.lineWeight * state.current.scale;
    if (state.current.weightVariation) {
      this.render.weight *= map(noiseFactor, 0, 1, 0.75, 1.75);
    }
    this.render.color = this.inputColor;
  }

  uniformTint() {
    this.render.active = true;
    this.render.weight = state.current.lineWeight;
    this.render.color = state.lineColor();
  }

  averageColor() {
    return this.colorHistory.average();
  }

  brightnessDeviation() {
    // because brightness is the value fn of ColorQueue
    if (this.colorHistory.empty) { return 1; }
    return map(this.colorHistory.std(), 0, 5, 0, 2);
  }

  averageBrightness() {
    if (this.inputColor === undefined) { return 0; }
    // if (this.inputColor === undefined || alpha(this.inputColor) === 0) { return 0; }
    const average = math.mean(this.colorHistory.values());
    return (average / 100) * (state.current.horizMovement);
  }

  draw() {
    const p = state.instance;

    let { x, y } = this;
    let { weight, xOffset, yOffset, active } = this.render;
    let cellColor = this.render.color;

    if (!active || !cellColor || p.alpha(cellColor) === 0) {
      return;
    }
    // if (frameCount === 80 && alpha(cellColor) < 10 && this.tintMethod === MIRROR) {
    //   console.log(alpha(cellColor), this);
    // }

    // p.push();
    let w = state.current.cellW * state.current.scale;
    let h = state.current.cellH * state.current.scale;
    let padding = h * (state.current.linePadding / 100)

    x += w * xOffset;
    y += yOffset;

    p.strokeCap(p.SQUARE);
    p.strokeWeight(weight);

    p.stroke(cellColor);
    p.line(
      x + w / 2.0, y + padding,
      x + w / 2.0, y + h - padding
    );

    if (this.debug) {
      p.noStroke();
      p.textSize(6);
      p.fill(255);
      p.text(`${this.row}\n${this.column}`, x + 2, y + 2);
    }

    // p.pop();
  }
}
