import Vue from "vue";
import Vue2TouchEvents from "vue2-touch-events";
import AsyncComputed from "vue-async-computed";
import App from "./App.vue";

Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents);
Vue.use(AsyncComputed);

new Vue({
  render: h => h(App)
}).$mount("#app");
