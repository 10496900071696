<template>
  <nav>
    <button
      v-for="deviceName in devices"
      :key="deviceName"
      :class="themeClass(deviceName)"
      @click.stop="updateDevice(deviceName)"
    >
      {{ deviceName }}
    </button>
  </nav>
</template>

<script>
export default {
  name: "DevicePicker",
  props: {
    devices: {
      type: Array,
      default: new Array(),
    },
    currentDevice: {
      type: String,
      default: "tab",
    },
  },
  methods: {
    themeClass(name) {
      return {
        [`device-${name}`]: true,
      };
    },
    updateDevice(theme) {
      this.$emit("device-changed", theme);
    },
  },
};
</script>

<style scoped>
.theme-startup {
  display: none;
}
button {
  margin: 0 0.25rem;
  background: rgba(200, 0, 0, 0.75);
  appearance: none;
  border: none;
  border-radius: 1rem;
  color: rgba(255, 255, 255, 0.85);
  padding: 0.125rem 0.5rem;
}
button:focus {
  outline: none;
}
</style>
