import { FaceDetectionReplies, FaceDetectionCommands, FaceDetectionMessage, FaceDetectionPayload, Face } from "./FaceDetectionInterface";

import FaceDetectionWorker from 'worker-loader!../workers/FaceDetectionWorker.worker';

export default class FaceDetectionFrontEnd {
  worker: Worker;
  operational: Boolean;
  callback: Function;

  constructor(callback: Function) {
    this.operational = false;
    this.worker = new FaceDetectionWorker();
    this.worker.addEventListener("message", this.messageReceived.bind(this));
    this.callback = callback;
  }

  detect(image: ImageData) {
    if (!this.operational) { return; }

    let type: FaceDetectionCommands = "image";
    let message: FaceDetectionMessage = {
      type,
      payload: image
    }
    this.worker.postMessage(message);
  }

  messageReceived(m: MessageEvent) {
    let message = m.data as FaceDetectionMessage;
    let messageType = message.type as FaceDetectionReplies;
    let payload: FaceDetectionPayload = message.payload;

    switch (messageType) {
      case "loaded":
        console.log("FaceDetectionWorker successfully loaded");
        this.operational = true;
        break;
      case "loadError":
        console.error("FaceDetectionWorker load error", payload);
        this.worker.terminate();
        this.operational = false;
        break;
      case "facesDetected":
        // console.log(`Detected ${(payload as Array<Face>).length} face(s):`, payload);
        if (this.callback) { this.callback(payload); }
        break;
      case "noFace":
        // console.log("No face detected");
        if (this.callback) { this.callback(payload); }
        break;
      default:
        // console.log("FaceDetectionWorker:", m.data);
        break;
    }
  }
}

if (window) {
  window.FaceDetectionFrontEnd = FaceDetectionFrontEnd;
}
