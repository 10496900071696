<template>
  <div :class="menuClass" @click.stop="clicked">
    <div v-if="icon" class="icon-container">
      <Icon v-if="active" :name="icon" small-h
            class="glow"
      />
      <Icon :name="icon" small-h />
    </div>
    <span v-if="!collapsed" class="label">{{ label }}</span>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  name: "MenuEntry",
  components: {
    Icon,
  },
  props: {
    active: Boolean,
    collapsed: Boolean,

    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    menuClass() {
      return {
        MenuEntry: true,
        MenuEntry__active: this.active,
        MenuEntry__noIcon: this.icon === null,
      }
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked', this.label);
    },
  },
}
</script>

<style scoped>
.MenuEntry {
  font-size: 1.25rem;

  display: grid;
  align-items: center;
  grid-template-columns: 2.5rem auto;
  height: 4rem;

  position: relative;
}
.MenuEntry::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 0;
  right: 0;
  background: var(--color-primary);
}
.MenuEntry__active::before {
  box-shadow: 0 0 5px var(--color-primary);
}
.MenuEntry__active .label {
  text-shadow: 0 0 5px rgba(255, 255, 255, .75);
  /* text-shadow: 0 0 5px var(--color-primary); */
}
.MenuEntry__noIcon {
  grid-template-columns: auto;
}

.icon-container {
  display: grid;
  justify-self: center;
}
.icon-container > * {
  grid-row: 1;
  grid-column: 1;
}
.Icon {
  justify-self: center;
}
.glow {
  filter: blur(1.25px);
}
</style>
<style>
  /* Some icons are out of proportion */
  /* .GeneralMenu .Icon > img { object-position: 0 0; } */
</style>
