export default class SceneState {
  constructor({ matrices, sceneParams }) {
    this.params = Object.assign({}, sceneParams);
    this.availableMatrices = Object.assign({}, matrices);
    
    this.current = {};
    this.matrices = [];
    
    this.setSharedSettings();
  }

  setSharedSettings() {
    this.w = window.innerWidth;
    this.h = window.innerHeight;
    this.frameRate = 30;

    this.params.alpha = 255;
  }

  matrixNamed(name) {
    return this.matrices.find(m => m.name === name);
  }

  active() {
    return this.matrices.filter(m => m.active);
  }

  setCurrentMatrix(m) {
    this.currentMatrix = m.name;
    this.current = m.params;
  }

  backgroundColor() {
    const p = this.instance;
    if (!this.cachedBackgroundColor) {
      this.cachedBackgroundColor = p.color(this.params.backgroundColor);
    }
    return this.cachedBackgroundColor;
  }

  lineColor() {
    const p = this.instance;
    if (!this.cachedLineColor) this.cachedLineColor = {};
    if (!this.cachedLineColor[this.currentMatrix]) {
      this.cachedLineColor[this.currentMatrix] = p.color(this.current.lineColor);
    }
    return this.cachedLineColor[this.currentMatrix];
  }

  invalidateParams() {
    this.matrices.forEach(m => {
      m.dirty = true;
    });
  }
}
