import Matrix from './Matrix';
import Cell from './Cell';
import * as Method from './TintMethods';
import p5 from 'p5';

window.p5 = p5;

function map(...args) {
  return state.instance.map(...args);
}

export default class AudioPlayer extends Matrix {
  constructor(name, params, active = true) {
    super(name, params, active);
    this.scopedParams = ["source", "amp", "fftSmoothing", "w", "h", "lineColorDay", "lineColorNight", "offsetX", "offsetY", "cellW", "cellH", "scale", "sensibility"];
  }
  initialize() {
    const p = state.instance;
    p.getAudioContext().suspend();
    p.userStartAudio("main");

    this.configure();
  }
  configure() {
    this.fft = new p5.FFT(this.params.fftSmoothing, 8192);
    const ampLevel = state.params.soundSources[this.params.source].amp;

    this.source = state.params.soundSources[this.params.source].source;
    this.source.amp(ampLevel);
    this.fft.setInput(this.source);
    this.cells = [];

    let cellOuterWidth = this.params.cellW * this.params.scale;
    let cellOuterHeight = this.params.cellH * this.params.scale;
    let numCol = Math.ceil(this.params.w / cellOuterWidth + 1);
    let numRow = Math.ceil(this.params.h / cellOuterHeight);

    for (let row = 0; row < numRow; row++) {
      for (let col = 0; col < numCol; col++) {
        let x = col * cellOuterWidth;
        let y = row * cellOuterHeight + this.params.offsetY;
        let cell = new Cell(x, y, row, col);
        cell.tintMethod = Method.DIRECT;
        cell.input(col);
        this.cells.push(cell);
      }
    }
  }

  baseColor() {
    if (!this.params.useAltTheme) {
      return this.params.lineColorComponents;
    }
    return this.params.altLineColorComponents;
  }

  isPlaying() {
    return this.source.isPlaying();
  }
  play() {
    this.source.loop();
  }
  pause() {
    this.source.pause();
  }
  volume(val) {
    this.source.amp(val);
  }


  computeOctaveBands() {
    const bands = this.fft.getOctaveBands(256, 15.625);
    const averages = this.fft.logAverages(bands);
    return averages;
  }

  valueAtPoint(x, y, distanceMax, bands) {
    const p = state.instance;

    let freq, energy;
    const perturbation = map(p.noise(x, y), 0, 1, -this.params.asymetry, this.params.asymetry);
    const distance = p.dist(x, y, this.params.w / 2, this.params.h / 2) + perturbation;

    freq = map(distance, 0, distanceMax, this.params.minFreq, this.params.maxFreq);
    energy = this.fft.getEnergy(freq);

    return energy;
  }

  colorForValue(energy, vol) {
    const p = state.instance;

    let [h, s] = this.baseColor();
    let b = Math.floor(energy * 2);
    let a = (b < 25) ? 0 : 1;
    let col = p.color(`hsba(${Math.floor(h)}, ${Math.floor(s)}%, ${b}%, ${a})`);
    return col;
  }

  tick() {
    const p = state.instance;

    this.fft.analyze();
    const ambientVolume = this.fft.getEnergy("mid");
    const bands = this.computeOctaveBands();
    const distanceMax = p.dist(0, 0, this.params.w / 2, this.params.h / 2);
    this.cells.forEach(c => {
      const value = this.valueAtPoint(c.x, c.y, distanceMax, bands);
      const colorForValue = this.colorForValue(value, ambientVolume);
      c.input(colorForValue);
    });
  }
}
