function ModeConfig(name) {
  this.name = name;
}

const Modes = Object.freeze({
  LongRange: new ModeConfig('longRange'),
  World: new ModeConfig('world'),
  // Sliders: new ModeConfig('sliders'),

  ShortRange: new ModeConfig('shortRange'),
  Overview: new ModeConfig('overview'),
  Apps: new ModeConfig('apps'),
  // MidRange: new ModeConfig('MidRange'),
  // Detailed: new ModeConfig('Detailed'),
});

function setMode(destination) {
  this.$emit('set-mode', destination);
}

function modeClasses(mainClass) {
  const modes = Object.keys(Modes)
  let classes = modes.reduce((h, m) => {
    const modeName = Modes[m].name
    h[`is-${modeName}`] = this.mode === Modes[m];
    return h;
  }, {});
  if (mainClass) {
    classes[mainClass] = true;
  }
  return classes;
}

export { setMode, modeClasses };
export default Modes;
