<template>
  <div v-touch:swipe="swipeBar">
    <div :class="{ AppGrid: true, 'AppGrid__visible': visible }">
      <div v-for="row in rowCount()" :key="row" class="AppGrid-Row"
           :data-row="row"
      >
        <div v-for="column in colCount()" :key="column" class="AppIcon"
             :style="staggering(row, column)"
             @click="openApp((row - 1) * 4 + column)"
        >
          <img :src="source(appGridIconName(row, column))" alt="Icon for an Android application">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: "AppIconList",
  props: {
    visible: Boolean,
    device: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  methods: {
    swipeBar(direction) {
      switch(direction) {
        case "bottom":
          this.$emit("hide-app-icon-list");
          break;
      };
    },

    appGridIconName(row, column) {
      return `app-icon-${(row - 1) * 4 + column}.png`;
    },
    source(name) {
      const dir = require.context('../assets/applications/', false, /\.png$/);
      return dir(`./${name}`);
    },
    staggering(row, column) {
      return {
        "--staggering": (row - 1) + (column - 1),
      }
    },
    openApp(id) {
      this.$emit("open", id);
    },
    rowCount() {
      if (this.height > 300) {
        return 6;
      }
      return 4;
    },
    colCount() {
      if (this.height > 300) {
        return 4;
      }
      if (this.height < 200) {
        return 0;
      }
      return 3;
    },
  },
}
</script>

<style scoped>
.AppIconList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 0 0;
  width: 100%;
}
.AppGrid {
  order: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  max-width: calc(4 * 3rem);
}
.AppGrid-Row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.AppIcon {
  margin: 0rem;
  height: 3rem;
  width: 2rem;
}
.AppGrid .AppIcon {
  opacity: 0;
  transform: scale(.25);
  transition: opacity .25s ease, transform .25s ease;
  transition-delay: calc((8 - var(--staggering)) * 0.05s);
}
.AppGrid__visible .AppIcon {
  opacity: 1;
  transform: scale(1);

  transition-delay: calc(var(--staggering) * 0.05s);
}

/* .Device__lutrin .AppGrid [data-row]:nth-child(n + 4) { */
.Device__lutrin .AppGrid [data-row="4"],
.Device__lutrin .AppGrid [data-row="3"] {
  display: none;
}
.Device__lutrin .AppBar {
  margin-top: 1rem;
}

</style>
