import p5 from 'p5';

export default class P5Canvas {
  constructor(state, containerID = 'canvas-container') {
    this.containerID = containerID;
    this.state = state;
    this.sketch = this.makeInstance();
    this.run();

    window.state = this.state;
  }

  run() {
    new p5(this.sketch, this.containerID);
  }

  updateState(newState) {
    this.state.instance.remove();
    // this.canvas.remove();
    if (this.capture) {
      this.capture.remove();
    }

    this.state = newState;
    window.state = this.state;

    this.sketch = this.makeInstance();
    this.run();
  }

  makeInstance() {
    const self = this;
    const state = self.state;
    const instance = p => {
      state.instance = p;

      p.preload = () => {
        if (state.params.soundSources) {
          const sounds = Object.keys(state.params.soundSources);
          sounds.forEach(s => {
            state.params.soundSources[s].source = p.loadSound(state.params.soundSources[s].file);
          });
        }
          // fonts.forEach(f => {
        // const fonts = Object.keys(state.fontSources);

        //   state.fontSources[f].source = p.loadFont(state.fontSources[f].file);
        // });
      };

      p.setup = () => {
        if (state.globalCapture) {
          self.capture = createCapture(VIDEO);
          self.capture.hide();
        }
        p.colorMode(p.HSB, 360, 100, 100, 1);
        // p.colorMode(p.RGB);
        p.loadMatrices();
        if (self.canvas) {
          self.canvas.remove();
        }
        self.canvas = p.createCanvas(state.w, state.h);
        // self.canvas = p.createCanvas(state.w, state.h, p.WEBGL);
        p.frameRate(state.frameRate);

        state.matrices.forEach(matrix => {
          state.setCurrentMatrix(matrix);
          matrix.initialize();
        });
        // noLoop();
      };

      p.loadMatrices = () => {
        const state = self.state;
        const available = Object.keys(state.availableMatrices);

        state.cachedMatrices = available.reduce((h, m) => { h[m] = state.params[m]; return h }, {});

        available.forEach(matrixName => {
          const matrixClass = state.availableMatrices[matrixName].class;
          const params = state.availableMatrices[matrixName].params;
          const settings = state.availableMatrices[matrixName].settings;

          const previousInstance = state.matrices[matrixName];
          if (previousInstance) { previousInstance.deinit(); }

          const matrix = new matrixClass(matrixName, params, state.params[matrixName], settings);
          state.matrices.push(matrix);
        });
      }

      p.draw = () => {
        state.active().forEach(m => {
          state.setCurrentMatrix(m);
          m.tick();
        });

        // drawCaptureIfNotOpaque();
        let bg = p.color(state.backgroundColor());

        bg.setAlpha(state.params.alpha / 255);
        p.background(bg);
        // p.translate(-state.w / 2.0, -state.h / 2.0);

        for (let m of state.active()) {
          state.setCurrentMatrix(m);
          for (let cell of m.cells) {
            cell.draw();
          }
          m.paintOverlay();
        }


      };
    };
    return instance;
  };
}
