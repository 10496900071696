<template>
  <div>
    <div ref="scroller" class="AppArea" :style="launcherStyle">
      <AppIconList v-if="context.height > 200"
                   :visible="appIconListVisible" :class="appIconListClass()"
                   :device="context.device" :height="context.height"
                   @hide-app-icon-list="closeAppList"
                   @open="openApp"
      />
      <RunningApp v-for="app in state.apps"
                  :id="app.name" :key="app.id"
                  :class="appClass(app.size)"
                  @click.native="circleSize(app, $event)"
      />
    </div>
  </div>
</template>

<script>
import RunningApp from "./RunningApp.vue";
import AppIconList from "./AppIconList.vue";
import { LauncherState, OpenApplication, SizeClass } from "../concerns/LauncherState";

import { wrapGrid } from "animate-css-grid";

const defaultApps = [
  new OpenApplication("4", SizeClass.Mobile),
  new OpenApplication("5", SizeClass.Widget),
  new OpenApplication("6", SizeClass.Widget),
  new OpenApplication("7", SizeClass.Mobile),
  new OpenApplication("8", SizeClass.Double),
  new OpenApplication("9", SizeClass.Mobile),
  new OpenApplication("10", SizeClass.Wide),
  new OpenApplication("11", SizeClass.Mobile),
  new OpenApplication("12", SizeClass.Widget),
  new OpenApplication("13", SizeClass.Mobile),
];

export default {
  name: "Launcher",
  components: {
    RunningApp,
    AppIconList,
  },
  props: {
    context: {
      type: Object,
      default: () => { return { height: 300, device: "grand-lutrin" } },
    },
    appIconListVisible: Boolean,
  },
  data() {
    return {
      pagingHeight: 0,
      state: new LauncherState(defaultApps),
      appIconListInvisible: true,
    };
  },
  computed: {
    launcherStyle() {
      return {
        // "overflow-x": (this.appIconListVisible) ? "hidden" : "scroll",
        "--app-height": `${this.context.height - this.pagingHeight}px`
      };
    },
  },
  watch: {
    appIconListVisible(visible) {
      if (visible) {
        clearTimeout(this.listTimeout);
        this.appIconListInvisible = false;
        requestAnimationFrame(() => {
          this.$refs.scroller.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
      } else {
        requestAnimationFrame(() => {
          this.$refs.scroller.querySelector(".RunningApp").scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
          this.listTimeout = setTimeout(() => { this.appIconListInvisible = true; }, 1000);
          // this.$refs.scroller.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.scroller.querySelector(".RunningApp").scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
    }, 1000)
    this.setup();
  },
  methods: {
    appClass(size) {
      return [
        "RunningApp",
        `RunningApp__${size}`,
      ];
    },
    appIconListClass() {
      return [
        'AppIconList',
        { AppIconList__visible: this.appIconListVisible },
        { AppIconList__invisible: this.appIconListInvisible },
      ];
    },
    closeAppList() {
      this.$emit("hide-app-icon-list");
    },
    openApp(id) {
      this.state.open(id);
    },
    setup() {
      if (this.gridUnwrapper) {
        Array.from(this.$refs.scroller.children).forEach(app => {
          delete app.dataset.animateGridId;
        });
        this.gridUnwrapper();
      }
      const { unwrapGrid } = wrapGrid(this.$refs.scroller, { easing: 'easeInOut', stagger: 10, duration: 400 });;
      this.gridUnwrapper = unwrapGrid;
    },
    circleSize(app, event) {
      this.setup();

      if (event.shiftKey) {
        app.circleDown();
      } else if (event.altKey) {
        this.state.quit(app);
      } else {
        app.circleUp();
      }

    },
  },
}
</script>

<style scoped>
.Launcher {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.AppArea {
  --app-width: calc(var(--app-height) * 9 / 16);

  overflow: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  display: grid;

  grid-template-rows: repeat(2, calc(var(--app-height) / 2 - .5rem));
  grid-auto-columns: var(--app-width);
  grid-auto-flow: column;

  justify-items: start;
  align-items: end;
  padding: 0 1rem;
}
.AppArea::-webkit-scrollbar {
  display: none;
}

.AppIconList__visible,
.RunningApp {
  scroll-snap-align: start;
}
.AppIconList__invisible {
  scroll-snap-align: none;
  position: absolute;
  width: 0;
  pointer-events: none;
}
.AppIconList {
  grid-column: span 0;
  grid-row: span 2;
  transition: grid-column .5s ease;
}

.RunningApp {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* padding: .5rem; */
  padding: calc(var(--app-width) / 33);
}
</style>
