<template>
  <div
    id="interface"
    :style="stylingProperties"
    :class="modeClasses('Interface')"
  >
    <Mirror ref="mirror" class="Mirror" :theme="theme"
            appearance="canvas"
    />
    <Splitter ref="splitter"
              :class="contentClass" :device="device" :expanded="menuExpanded"
              @expand-menu="setExpanded(true)" @unexpand-menu="setExpanded(false)">
      <template #top>
        <Controller class="Controller"
                    :expanded="menuExpanded" :indicatorsVisible="indicatorsVisible"
                    @show-indicators="showIndicators" />
      </template>

      <template v-if="device !== 'lutrin'" #bottom="context">
        <Launcher class="Launcher"
          :context="context" :appIconListVisible="appIconListVisible" @hide-app-icon-list="closeAppList"
        />
      </template>
    </Splitter>
    <AppControls class="AppControls" @toggle-switcher="clickedSwitcher" @toggle-launcher="clickedLauncher" />

    <!-- <FaceDetector @changed="setRange" /> -->
  </div>
</template>

<script>
import gsap from "gsap";

import Mirror from "./components/Mirror.vue";
import Splitter from "./components/Splitter.vue";
import Controller from "./components/Controller.vue";
import Launcher from "./components/Launcher.vue";

import AppControls from "./components/AppControls.vue";
// import FaceDetector from "./components/FaceDetector";

import Modes, { modeClasses } from "./Modes";

import FaceDetectionFrontend from "./concerns/FaceDetectionFrontend";

export default {
  name: "Interface",
  components: {
    Mirror,
    Splitter,
    Controller,
    Launcher,
    AppControls,
    // FaceDetector,
  },
  props: {
    device: {
      type: String,
      default: "tab",
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Modes,
      mode: Modes.LongRange,

      indicatorsVisible: false,
      menuExpanded: false,
      appIconListVisible: false,
    };
  },
  computed: {
    stylingProperties() {
      return {
        "--color-clock": this.theme.sceneParams.clockColor,
        "--color-panel-background": this.theme.sceneParams.panelBackground,
        "--color-apps-background": this.theme.sceneParams.appsBackground,
        "--color-primary": this.theme.sceneParams.colorPrimary,
        "--color-secondary": this.theme.sceneParams.colorSecondary,
        "--color-vibrant": this.theme.sceneParams.panelVibrant,
        "--color-panel-separator": this.theme.sceneParams.panelSeparator,
        "--separator-height": "3px",
        "--value-height": "0.5px",
      };
    },
    contentClass() {
      return {
        Splitter: true,
        "is-scrolling": this.contentScrolling,
      };
    },
  },
  mounted() {
    // setTimeout(() => {
      // this.setTheme("main");
    // }, 2000);
    // this.$nextTick(() => {
      // this.showContent = true;
    // });
  },
  methods: {
    modeClasses,
    setMode(mode) {
      console.log(this.mode.name, "->", mode.name);
      this.mode = mode;
    },
    deviceChanged() {
      this.$refs.mirror.reload();
      this.$refs.splitter.setup();
    },

    setExpanded(bool) {
      this.menuExpanded = bool;
    },

    clickedLauncher() {
      this.appIconListVisible = !this.appIconListVisible;
    },
    closeAppList() {
      this.appIconListVisible = false;
    },
    clickedSwitcher() {
      this.setExpanded(!this.menuExpanded);
    },
    showIndicators() {
      clearTimeout(this.indicatorsTimeout);
      this.indicatorsVisible = true;
      this.indicatorsTimeout = setTimeout(() => {
        this.hideIndicators();
      }, 5000);
    },
    hideIndicators() {
      this.indicatorsVisible = false;
    }
  },
};
</script>
<style scoped lang="scss">
.Mirror {
  position: absolute;
}
.Splitter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 2.5rem;
  color: var(--color-primary);
}
.Controller {
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
}
.Launcher {
  margin: 0;
}
// .AppLauncher,
// .AppSwitcher {
//   width: fit-content;
//
//   opacity: 0;
//   transition: opacity .5s ease;
//   transition-delay: 0;
//   pointer-events: none;
// }
// .AppLauncher__visible,
// .AppSwitcher__visible {
//   opacity: 1;
//   // transition-delay: .5s;
//   order: -1;
//   pointer-events: all;
// }
.AppControls {
  position: absolute;
  bottom: .75rem;
  left: 1rem;
}

</style>
