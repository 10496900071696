<template>
  <div>
    <div :class="levelClass(1)">
      <MenuEntry v-for="item in level(1)" :key="label(item)"
                 :active="keyPath[0] === item"
                 :collapsed="!!keyPath[0]"
                 :label="label(item)"
                 :icon="icon(item)"
                 class="MenuEntry"
                 @clicked="select(item)"
      />
    </div>
    <div v-if="keyPath[0]" :class="levelClass(2)">
      <MenuEntry v-for="item in level(2)" :key="label(item)"
                 :active="keyPath[1] === item"
                 :label="label(item)"
                 :icon="icon(item)"
                 class="MenuEntry"
                 @clicked="select(keyPath[0], item)"
      />
    </div>
    <div v-if="keyPath[1]" :class="levelClass(3)">
      <MenuEntry v-for="item in level(3)" :key="label(item)"
                 :active="keyPath[2] === item"
                 :label="label(item)"
                 :icon="icon(item)"
                 class="MenuEntry"
                 @clicked="select(keyPath[0], keyPath[1], item)"
      />
    </div>
    <div v-if="keyPath[2]" :class="levelClass(4)">
      <MenuEntry v-for="item in level(4)" :key="label(item)"
                 :active="keyPath[3] === item"
                 :label="label(item)"
                 :icon="icon(item)"
                 class="MenuEntry"
                 @clicked="select(keyPath[0], keyPath[1], keyPath[2], item)"
      />
    </div>

    <div v-if="currentSetting.name" class="CurrentSetting">
      <LineSlider
        :value="currentSetting.value"
        :icon="currentSetting.icon"
        identifier="slider1"
        :name="currentSetting.name"
        @changed="sliderChanged"
      />
    </div>
  </div>
</template>

<script>
import MenuEntry from "./MenuEntry.vue";
import LineSlider from "./LineSlider.vue";
import { parameterize } from "inflected";
// import Icon from "./Icon.vue";

export default {
  name: "GeneralMenu",
  components: {
    MenuEntry,
    LineSlider,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keyPath: [],
      currentSetting: {
        name: null,
        icon: null,
        value: 0,
      },
    };
  },
  methods: {
    levelOne() {
      return Object.keys(this.menu);
    },
    levelTwo(one) {
      const branch = this.menu[one];
      if (Array.isArray(branch)) {
        return branch;
      }
      return Object.keys(this.menu[one]);
    },
    level(depth) {
      let branch = this.menu;
      for (let i = 0; i < depth; i++) {
        if (this.keyPath[i - 1]) {
          const lastPathItem = this.keyPath[i - 1];
          if (branch[lastPathItem]) {
            branch = branch[lastPathItem];
          } else if (branch.find(node => node === lastPathItem)) {
            branch = null;
            return null;
          } else {
            branch = branch.find(node => {
              return typeof node === "object" && Object.keys(node)[0] === lastPathItem;
            })[lastPathItem];
          }
        }
      }
      if (Array.isArray(branch)) {
        return branch;
      }
      return Object.keys(branch);
    },
    levelClass(depth) {
      const parent = this.keyPath[depth - 2];
      let parentName = (parent) ? parameterize(this.label(parent)) : null;
      return [
        "Menu-Level",
        `Menu-Level-${depth}`,
        { [`Menu-${parentName}`] : !!parent },
      ];
    },
    label(item) {
      let string = item;
      if (typeof string !== "string") {
        string = Object.keys(item)[0];
      }
      let match = string.match(/(.*) \(.*\)/);
      return (match) ? match[1] : string;
    },
    icon(item) {
      let string = item;
      if (typeof string !== "string") {
        string = Object.keys(item)[0];
      }
      let match = string.match(/.*\((.*)\)/);
      return (match) ? match[1] : null;
    },
    select(...args) {
      this.keyPath = args.map(arg => {
        if (typeof arg === "string") {
          return arg;
        }
        return Object.keys(arg)[0];
      });

      // console.log(this.level(this.keyPath.length));
      if (this.level(this.keyPath.length + 1) === null) {
        this.triggerPanel();
      } else {
        this.closePanel();
      }
    },
    triggerPanel() {
      const currentSetting = this.keyPath[this.keyPath.length - 1];
      this.currentSetting.name = this.label(currentSetting);
      this.currentSetting.icon = this.icon(currentSetting);
      this.currentSetting.value = Math.floor(Math.random() * 67);
    },
    closePanel() {
      this.currentSetting.name = null;
      this.currentSetting.icon = null;
    },
    sliderChanged(slider, value) {
      this.currentSetting.value = value;
    },
  },
}
</script>

<style scoped>
.GeneralMenu {
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
}
.Menu-Level {
  display: grid;
  grid-auto-rows: 4rem;
}
.Menu-favorites {
  grid-template-columns: auto auto;
  column-gap: 2rem;
}
.CurrentSetting {
  grid-column: 5;

  display: flex;
  align-items: flex-end;
  height: 12.5rem;
  width: 100%;
  max-width: 20rem;
  margin-top: 5rem;
  margin-right: 2rem;
  justify-self: end;
}

.Menu-actions + .Menu-Level-3 {
  grid-column: 5;
  pointer-events: none;
  /* max-width: 40rem; */
  /* justify-self: end; */
}
</style>
