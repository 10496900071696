import * as math from 'mathjs';

export default class Queue {
  constructor(capacity = 5) {
    this.capacity = capacity;
    this.storage = [];
  }

  enqueue(val) {
    this.storage.push(val);
    if (this.storage.length > this.capacity) {
      this.storage.shift();
    }
  }

  every(fn) {
    return this.storage.every(fn);
  }

  last() {
    return this.storage[this.storage.length - 1];
  }

  previous() {
    return this.storage[this.storage.length - 2];
  }

  values() {
    return this.storage;
  }

  average() {
    return math.mean(this.values());
  }

  variance() {
    return math.variance(this.values());
  }

  std() {
    return math.std(this.values());
  }

  get empty() {
    return this.storage.length === 0;
  }
}
