class LauncherState {
  apps: Array<OpenApplication>;
  constructor(apps: Array<OpenApplication> = []) {
    this.apps = apps;
  }

  quit(app: OpenApplication) {
    this.apps = this.apps.filter(a => a.id !== app.id);
  }
  open(id: number) {
    const app = new OpenApplication(`${(id % 10) + 3}`);
    this.apps.unshift(app);
  }
}

class LauncherScreen {
  apps: Array<OpenApplication> = [];
  id: Number

  constructor(id: number) {
    this.id = id;
  }
}

class OpenApplication {
  name: String;
  size: SizeClass;
  id: String;

  constructor(appName: String, size: any = SizeClass.Mobile) {
    this.name = appName;
    this.size = size;
    this.id = this.uuidv4();
  }

  circleUp() {
    switch (this.size) {
      case SizeClass.Widget:
        this.size = SizeClass.Mobile;
        break;
      case SizeClass.Mobile:
        this.size = SizeClass.Double;
        break;
      case SizeClass.Double:
        this.size = SizeClass.Wide;
        break;
      case SizeClass.Wide:
        this.size = SizeClass.Full;
        break;
      case SizeClass.Full:
        this.size = SizeClass.Mobile;
        break;
    }
  }

  circleDown() {
    switch (this.size) {
      case SizeClass.Widget:
        this.size = SizeClass.Wide;
        break;
      case SizeClass.Mobile:
        this.size = SizeClass.Widget;
        break;
      case SizeClass.Double:
        this.size = SizeClass.Mobile;
        break;
      case SizeClass.Wide:
      case SizeClass.Full:
        this.size = SizeClass.Double;
        break;
    }
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}

enum SizeClass {
  Mobile = "Mobile",
  Widget = "Widget",
  Double = "Double",
  Wide = "Wide",
  Full = "Full",
}

export { LauncherState, OpenApplication, SizeClass };
