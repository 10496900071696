<template>
  <main>
    <Device :type="deviceType">
      <Interface ref="interface" class="Interface" :device="deviceType"
                 :theme="theme "
      />
    </Device>
    <div class="Utilities">
      <DevicePicker :current-device="deviceType" :devices="devices" @device-changed="setDevice" />
      <ThemePicker class="ThemePicker" :themes="Object.keys(Themes)" @update-theme="setTheme" />
    </div>
  </main>
</template>

<script>
import Interface from "./Interface.vue";
import Device from "./Device.vue";
import DevicePicker from "./components/DevicePicker.vue";
import ThemePicker from "./components/ThemePicker.vue";
import Themes from './Themes';

export default {
  components: {
    Interface,
    Device,
    DevicePicker,
    ThemePicker,
  },
  data() {
    return {
      deviceType: "grand-lutrin",
      devices: ["grand-lutrin", "petit-lutrin", "none"],
      Themes,
      theme: Themes.startup,
    };
  },
  methods: {
    setDevice(device) {
      setTimeout(() => { this.$refs.interface.deviceChanged() }, 100);
      this.deviceType = device;
    },
    setTheme(themeName) {
      this.theme = Themes[themeName];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/fonts.scss";
@import "@/assets/panel.scss";
.Utilities {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
}
.Interface {
  width: 100%;
  height: 100%;
}
</style>
