function bindEvents() {
  document.addEventListener("touchmove", this.moving, { passive: false });
  document.addEventListener("mousemove", this.moving);
  document.addEventListener("touchend", this.moveEnd, { passive: false });
  document.addEventListener("mouseup", this.moveEnd);
  document.addEventListener("mouseleave", this.moveEnd);
  window.addEventListener("resize", this.setup);
  window.addEventListener("orientationchange", this.setup);
}
function unbindEvents() {
  document.removeEventListener("touchmove", this.moving);
  document.removeEventListener("touchend", this.moveEnd);
  document.removeEventListener("mousemove", this.moving);
  document.removeEventListener("mouseup", this.moveEnd);
  document.removeEventListener("mouseleave", this.moveEnd);
  document.removeEventListener("resize", this.setup);
  document.removeEventListener("orientationchange", this.setup);
}
function moveStart(e) {
  if (!this.draggable) { return; }
  this.dragging = true;
  this.$emit("drag-start", this);

  const y = (e.targetTouches && e.targetTouches[0]) ? e.targetTouches[0].clientY : e.clientY;
  this.initialDragY = y;
  this.initialDragOffsetTop = e.target.offsetTop;
}
function moving(e) {
  if (!this.dragging) { return; }
  e.preventDefault();

  const y = (e.targetTouches && e.targetTouches[0]) ? e.targetTouches[0].clientY : e.clientY;
  this.dragDistance = this.initialDragY - y;

  if (this.recalculate) {
    this.recalculate();
  }
}
function moveEnd(e) {
  if (!this.dragging) { return; }
  e.stopPropagation();
  e.preventDefault();
  this.$emit("drag-end", this);

  if (this.snap) {
    this.snap();
  }

  this.dragging = false;
  this.dragDistance = 0;
}

export {
  bindEvents, unbindEvents, moveStart, moving, moveEnd,
};
