<template>
  <figure :class="figureClass()">
    <img v-if="name" :src="imgSrc(name)" :style="imgStyle">
    <div v-else class="Ball" :style="ballStyle">
      <span v-if="overlay" class="Overlay">{{ overlay }}</span>
    </div>
    <figcaption v-if="labelled">
      <slot></slot>
    </figcaption>
  </figure>
</template>
<script>
export default {
  props: {
    name: String,
    labelled: Boolean,
    color: String,
    overlay: String,

    medium: Boolean,
    small: Boolean,
    smallH: Boolean,
    tiny: Boolean,
    custom: Boolean,

    invert: Boolean,
  },
  data() {
    return {
      imgSrc(name) {
        const images = require.context('../assets/icons', true, /\.svg$/);
        return images(`./${name}.svg`);
      },
      figureClass() {
        return {
          "Icon": true,
          medium: this.medium,
          small: this.small,
          smallH: this.smallH,
          tiny: this.tiny,
          custom: this.custom,
        };
      },
    };
  },
  computed: {
    ballStyle() {
      return { backgroundColor: (this.color) ? this.color : 'blue' };
    },
    imgStyle() {
      return { filter: this.invert ? 'invert(1)' : '' };
    },
  },
};
</script>
<style scoped>
  figure {
    width: 4rem;
    height: 4rem;
    padding: .125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  figure > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  figure.medium {
    width: 3rem;
    height: 3rem;
  }
  figure.small {
    padding: .25rem;
    width: 2rem;
    height: 2rem;
  }
  figure.smallH {
    padding: .25rem;
    width: auto;
    height: 2rem;
  }
  figure.tiny {
    width: 1.5rem;
    height: 1.5rem;
  }
  figure.custom {
    width: var(--icon-size);
    height: var(--icon-size);
  }
  .Ball {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Overlay {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, .66);
  }
  figcaption {
    font-size: .8rem;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
  }
</style>
