<template>
  <nav>
    <button
      v-for="themeName in themes"
      :key="themeName"
      :class="themeClass(themeName)"
      @click.stop="updateTheme(themeName)"
    >
      {{ themeName }}
    </button>
    <button @click.stop="toggle">
      {{ playPause }}
    </button>
  </nav>
</template>

<script>
export default {
  name: 'ThemePicker',
  props: {
    themes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      paused: false,
    }
  },
  computed: {
    playPause() {
      return (this.paused) ? "Unpause mirror" : "Pause mirror";
    },
  },
  methods: {
    themeClass(name) {
      return {
        [`theme-${name}`]: true,
      };
    },
    updateTheme(theme) {
      this.$emit('update-theme', theme);
    },
    toggle() {
      this.paused = !this.paused;
      if (this.paused) {
        state.instance.noLoop();
      } else {
        state.instance.loop();
      }
    },
  },

};
</script>

<style scoped>
.theme-startup {
  display: none;
}
button {
  margin: 0 .25rem;
  background: rgba(0, 0, 0, .75);
  appearance: none;
  border: none;
  border-radius: 1rem;
  color: rgba(255, 255, 255, .85);
  padding: .125rem .5rem;
}
button:focus {
  outline: none;
}
</style>
